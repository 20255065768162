import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const BASEURL = environment.apiUrl;
const httpOptions = {
    headers: new HttpHeaders({
        'content-Type': 'application/json'
    })
};
export class AuthService {
    //countUrl = environment.apiUrl + 'bills/count';
    constructor(http) {
        this.http = http;
    }
    registerUser(body) {
        return this.http.post(`${BASEURL}/register`, body);
    }
    loginUser(body) {
        return this.http.post(`${BASEURL}/login`, body);
    }
    requestReset(body) {
        //console.log(`${BASEURL}users/req-reset-password`);
        return this.http.post(`${BASEURL}users/req-reset-password`, body, httpOptions);
    }
    newPassword(body) {
        return this.http.post(`${BASEURL}users/response-reset-password`, body, httpOptions);
    }
    ValidPasswordToken(body) {
        return this.http.post(`${BASEURL}users/valid-password-token`, body, httpOptions);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
