import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const httpOptions = {
    headers: new HttpHeaders({
        'content-Type': 'application/json'
    })
};
export class GroupService {
    constructor(http) {
        this.http = http;
        // // public subscription = new Subscription(null, null, null);
        // // URL to web api
        // allUrl = environment.apiUrl + 'subscription/find';
        // setActiveUrl = environment.apiUrl + 'subscription/setactive';
        // setBlockUrl = environment.apiUrl + 'subscription/setblock';
        // /* --- ABANDONED in v2 Start --- */
        // acceptUrl = environment.apiUrl + 'subscription/approve';
        // tenBlockedUrl = environment.apiUrl + 'subscription/findTenBlocked';
        // tenWaitingUrl = environment.apiUrl + 'subscription/findTenWaiting';
        // /* --- ABANDONED in v2 End --- */
        // findfollowingUrl = environment.apiUrl + 'subscription/findfollowing';
        // checkRelationshipUrl = environment.apiUrl + 'subscription/check';
        // createUrl = environment.apiUrl + 'subscription/create';
        // /**
        //  * user-group URL
        //  */
        // userGroupCreateUrl = environment.apiUrl + 'group/create';
        // userGroupDeleteUrl = environment.apiUrl + 'group/delete';
        // userGroupRetrieveUrl = environment.apiUrl + 'group/retrieve';
        // updateGroupUrl = environment.apiUrl + 'group/update';
        // public subscription = new Subscription(null, null, null);
        // URL to web api
        this.allUrl = environment.apiUrl + 'subscriptions/allSubscriptions';
        this.setActiveUrl = environment.apiUrl + 'subscriptions/setActive';
        this.setBlockUrl = environment.apiUrl + 'subscriptions/setBlock';
        this.findfollowingUrl = environment.apiUrl + 'subscriptions/findfollowing';
        this.checkRelationshipUrl = environment.apiUrl + 'subscriptions/check';
        this.createUrl = environment.apiUrl + 'subscriptions/subscription';
        this.subAllCountUrl = environment.apiUrl + 'subscriptions/getAllCount';
        /**
         * user-group URL
         */
        this.userGroupCreateUrl = environment.apiUrl + 'userGroups/userGroup';
        this.userGroupDeleteUrl = environment.apiUrl + 'userGroups/deleteUserGroup';
        this.userGroupRetrieveUrl = environment.apiUrl + 'userGroups/allUserGroups';
        this.updateGroupUrl = environment.apiUrl + 'userGroups/updateGroup';
        this.GetAllCountUrl = environment.apiUrl + 'userGroups/getAllCount';
    }
    updateGroup(groupId, groupMember) {
        const creatorJSON = '{ "groupId" : ' +
            '"' +
            groupId +
            '"' +
            ', ' +
            '"groupMember" : ' +
            '"' +
            groupMember +
            '"' +
            ' }';
        return this.http.post(this.updateGroupUrl, JSON.parse(creatorJSON), httpOptions);
    }
    //groups-active-list
    allSubscribers(company, filter = '', skip = 0, pageSize = 10000000000) {
        //const creatorJSON = '{ "company" : ' + '"' + company + '"' + ' }';
        // console.log(JSON.parse(creator));
        let creatorJSON = '{ "creator" : ' + '"' + company + '"' + ',"skip" : ' + skip.toString() + ',"pageSize" : ' + pageSize.toString() + ' }';
        if (filter.length != 0) {
            creatorJSON = '{ "creator" : ' + '"' + company + '",' + '"filter" : ' + '"' + filter.toString() + '"' + ',"skip" : ' + skip.toString() + ',"pageSize" : ' + pageSize + ' }';
        }
        //debugger;
        return this.http.post(this.allUrl, JSON.parse(creatorJSON), httpOptions);
    }
    setActive(subscription) {
        return this.http.post(this.setActiveUrl, subscription, httpOptions);
    }
    setBlock(subscription) {
        return this.http.post(this.setBlockUrl, subscription, httpOptions);
    }
    findfollowing(individual) {
        const creatorJSON = '{ "individual" : ' + '"' + individual + '"' + ' }';
        // console.log(JSON.parse(creator));
        return this.http.post(this.findfollowingUrl, JSON.parse(creatorJSON), httpOptions);
    }
    checkRelationship(individual, company) {
        const creatorJSON = '{ "individual" : ' +
            '"' +
            individual +
            '"' +
            ', ' +
            '"company" : ' +
            '"' +
            company +
            '"' +
            ' }';
        // console.log(creatorJSON);
        return this.http.post(this.checkRelationshipUrl, JSON.parse(creatorJSON), httpOptions);
    }
    create(individual, company, email) {
        const creatorJSON = '{ "individual" : ' +
            '"' +
            individual +
            '"' +
            ', ' +
            '"company" : ' +
            '"' +
            company +
            '"' +
            ', ' +
            '"email" : ' +
            '"' +
            email +
            '"' +
            ' }';
        return this.http.post(this.createUrl, JSON.parse(creatorJSON), httpOptions);
    }
    /**
     *
     * user-group service
     */
    groupCreate(group) {
        return this.http.post(this.userGroupCreateUrl, group, httpOptions);
    }
    groupDelete(id) {
        const creatorJSON = '{ "id" : ' + '"' + id + '"' + ' }';
        return this.http.post(this.userGroupDeleteUrl, JSON.parse(creatorJSON), httpOptions);
    }
    //user-groups
    groupRetrieve(creator, filter = '', skip = 0, pageSize = 10000000000) {
        //const creatorJSON = '{ "creator" : ' + '"' + creator + '"' + ' }';
        let creatorJSON = '{ "creator" : ' + '"' + creator + '"' + ',"skip" : ' + skip.toString() + ',"pageSize" : ' + pageSize.toString() + ' }';
        if (filter.length != 0) {
            creatorJSON = '{ "creator" : ' + '"' + creator + '",' + '"filter" : ' + '"' + filter.toString() + '"' + ',"skip" : ' + skip.toString() + ',"pageSize" : ' + pageSize + ' }';
        }
        //debugger;
        return this.http.post(this.userGroupRetrieveUrl, JSON.parse(creatorJSON), httpOptions);
    }
    //返回数据总条数
    getAllCount(company, filter, groupstype) {
        const creatorJSON = '{ "creator" : ' + '"' + company + '","filter" : "' + filter + '","groups" : "' + groupstype + '" }';
        return this.http.post(this.GetAllCountUrl, JSON.parse(creatorJSON), httpOptions);
    }
    //返回数据总条数
    subAllCount(company, filter, groupstype) {
        const creatorJSON = '{ "creator" : ' + '"' + company + '","filter" : "' + filter + '","groups" : "' + groupstype + '" }';
        return this.http.post(this.subAllCountUrl, JSON.parse(creatorJSON), httpOptions);
    }
}
GroupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GroupService_Factory() { return new GroupService(i0.ɵɵinject(i1.HttpClient)); }, token: GroupService, providedIn: "root" });
